import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
//todo find correct types for new Subject<any>();
export class DeviceShareService {
  private devices = new Subject<any>();
  private select_marker = new Subject<any>();
  private close_detail_view = new Subject<any>();
  private filter_result = new Subject<any>();
  private close_filter = new Subject<any>();
  private device_view = new Subject<any>();
  private open_child_device = new Subject<any>();
  private open_parent_device = new Subject<any>();
  private detail_view = new Subject<any>();
  public open_detail_view = new Subject<any>();
  public toggle_options = new Subject<any>();
  public audio_request_detail_view = new Subject<any>();
  public flow_direction = new Subject<any>();
  public open_nbiot_remote_config = new Subject<any>();
  public open_du_remote_config = new Subject<any>();
  public close_remote = new Subject<any>();
  public enable_device_drag = new Subject<any>();
  public get_device_coordinates = new Subject<any>();
  public update_device_coordinates = new Subject<any>();
  public nbiot_remote_config = new Subject<any>();
  public du_remote_config = new Subject<any>();
  public d3_remote_config = new Subject<any>();
  public gtbox_remote_config = new Subject<any>();
  public smartear_remote_config = new Subject<any>();
  public graph_filter_view = new Subject<any>();
  constructor() {

  }
  public share_device(device: any): void {
    this.devices.next(device);
  }

  public get_devices(): Observable<any> {
    return this.devices.asObservable();
  }

  public share_selected_marker(marker: any): void {
    this.select_marker.next(marker);
  }

  public get_selected_marker(): Observable<any> {
    return this.select_marker.asObservable();
  }

  public close_view(device: any): void {
    this.close_detail_view.next(device);
  }

  public is_close_event(): Observable<any> {
    return this.close_detail_view.asObservable();
  }

  public share_filter_result(filter_data: any): void {
    this.filter_result.next(filter_data);
  }

  public get_filter_result(): Observable<any> {
    return this.filter_result.asObservable();
  }

  public fire_close_event(): void {
    this.close_filter.next(false);
  }

  public recieve_close_event(): Observable<any> {
    return this.close_filter.asObservable();
  }

  public device_view_switch(viewType: any): void {
    this.device_view.next(viewType);
  }

  public get_changed_view(): Observable<any> {
    return this.device_view.asObservable();
  }

  public open_child_device_event(device_detail: any): void {
    this.open_child_device.next(device_detail);
  }

  public child_device_event(): Observable<any> {
    return this.open_child_device.asObservable();
  }

  public open_parent_device_event(device_detail: any): void {
    this.open_parent_device.next(device_detail);
  }

  public parent_device_event(): Observable<any> {
    return this.open_parent_device.asObservable();
  }

  public map_date_change(toggle: any): void {
    this.detail_view.next(toggle);
  }

  public map_date_change_event(): Observable<any> {
    return this.detail_view.asObservable();
  }

  public open_detail_view_event(value: any): void {
    this.open_detail_view.next(value);
  }

  public toggle_option_event(option: string): void {
    this.toggle_options.next(option);
  }

  public audio_request_event(option: any): void {
    this.audio_request_detail_view.next(option);
  }

  public share_flow_direction(value: any): void {
    this.flow_direction.next(value);
  }

  public open_nbiot_remote_config_event(device_info: any): void {
    this.open_nbiot_remote_config.next(device_info);
  }

  public open_du_remote_config_event(device_info: any): void {
    this.open_du_remote_config.next(device_info);
  }

  public close_remote_config(device_info?: any): void {
    this.close_remote.next(device_info);
  }

  public device_drag_event(device_info: any): void {
    this.enable_device_drag.next(device_info);
  }

  public update_device_coordinates_event(device_cords: any): void {
    this.update_device_coordinates.next(device_cords);
  }

  public open_nbiot_remote_config_function(device_info: any): void {
    this.nbiot_remote_config.next(device_info);
  }

  public open_du_remote_config_function(device_info: any): void {
    this.du_remote_config.next(device_info);
  }

  public open_d3_remote_config_function(device_info: any): void {
    this.d3_remote_config.next(device_info);
  }

  public open_gtbox_remote_config_function(device_info: any): void {
    this.gtbox_remote_config.next(device_info);
  }

  public open_smartear_remote_config_function(device_info: any): void {
    this.smartear_remote_config.next(device_info);
  }

  public open_graph_filter_view(config: any): void {
    this.graph_filter_view.next(config);
  }
}
