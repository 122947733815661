import * as noUiSlider from 'nouislider';
import * as i0 from '@angular/core';
import { EventEmitter, forwardRef, Component, Input, Output, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
class DefaultFormatter {
  to(value) {
    // formatting with http://stackoverflow.com/a/26463364/478584
    return String(parseFloat(parseFloat(String(value)).toFixed(2)));
  }
  from(value) {
    return parseFloat(value);
  }
}
class NouisliderComponent {
  constructor(ngZone, el, renderer) {
    this.ngZone = ngZone;
    this.el = el;
    this.renderer = renderer;
    this.handles = [];
    this.config = {};
    this.change = new EventEmitter(true);
    this.update = new EventEmitter(true);
    this.slide = new EventEmitter(true);
    this.set = new EventEmitter(true);
    this.start = new EventEmitter(true);
    this.end = new EventEmitter(true);
    this.onChange = Function.prototype;
    this.cleanups = [];
    this.eventHandler = (emitter, values, handle, unencoded) => {
      let v = this.toValues(values);
      let emitEvents = false;
      if (this.value === undefined) {
        this.value = v;
        return;
      }
      if (Array.isArray(v) && this.value[handle] != v[handle]) {
        emitEvents = true;
      }
      if (!Array.isArray(v) && this.value != v) {
        emitEvents = true;
      }
      if (emitEvents) {
        this.ngZone.run(() => {
          if (emitter.observers.length > 0) {
            emitter.emit(v);
          }
          this.onChange(v);
        });
      }
      if (Array.isArray(v)) {
        this.value[handle] = v[handle];
      } else {
        this.value = v;
      }
    };
    this.defaultKeyHandler = e => {
      let stepSize = this.slider.steps();
      let index = parseInt(e.target.getAttribute('data-handle'));
      let sign = 1;
      let multiplier = 1;
      let step = 0;
      let delta = 0;
      switch (e.which) {
        case 34:
          // PageDown
          multiplier = this.config.pageSteps;
          break;
        case 40: // ArrowDown
        case 37:
          // ArrowLeft
          sign = -1;
          step = stepSize[index][0];
          e.preventDefault();
          break;
        case 33:
          // PageUp
          multiplier = this.config.pageSteps;
          break;
        case 38: // ArrowUp
        case 39:
          // ArrowRight
          step = stepSize[index][1];
          e.preventDefault();
          break;
        default:
          break;
      }
      delta = sign * multiplier * step;
      let newValue;
      if (Array.isArray(this.value)) {
        newValue = [...this.value];
        newValue[index] = newValue[index] + delta;
      } else {
        newValue = this.value + delta;
      }
      this.slider.set(newValue);
    };
  }
  ngOnChanges(changes) {
    if (this.slider && (changes.min || changes.max || changes.step || changes.range)) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.slider.updateOptions({
            range: Object.assign({}, {
              min: this.min,
              max: this.max
            }, this.range || {}),
            step: this.step
          });
        });
      });
    }
  }
  ngOnDestroy() {
    this.slider.destroy();
    while (this.cleanups.length) {
      this.cleanups.pop()?.();
    }
  }
  toValues(values) {
    let v = values.map(this.config.format.from);
    return v.length == 1 ? v[0] : v;
  }
  writeValue(value) {
    if (this.slider) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.slider.set(value);
        });
      });
    } else if (value !== null) {
      this.value = value;
      this.createSlider(value);
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {}
  setDisabledState(isDisabled) {
    isDisabled ? this.renderer.setAttribute(this.el.nativeElement.childNodes[0], 'disabled', 'true') : this.renderer.removeAttribute(this.el.nativeElement.childNodes[0], 'disabled');
  }
  createSlider(initialValue) {
    let inputsConfig = JSON.parse(JSON.stringify({
      behaviour: this.behaviour,
      connect: this.connect,
      limit: this.limit,
      start: initialValue,
      step: this.step,
      pageSteps: this.pageSteps,
      keyboard: this.keyboard,
      onKeydown: this.onKeydown,
      range: this.range || this.config.range || {
        min: this.min,
        max: this.max
      },
      tooltips: this.tooltips,
      snap: this.snap,
      animate: this.animate
    }));
    inputsConfig.tooltips = this.tooltips || this.config.tooltips;
    inputsConfig.format = this.format || this.config.format || new DefaultFormatter();
    this.ngZone.runOutsideAngular(() => {
      this.slider = noUiSlider.create(this.el.nativeElement.querySelector('div'), Object.assign(this.config, inputsConfig));
    });
    this.handles = [].slice.call(this.el.nativeElement.querySelectorAll('.noUi-handle'));
    if (this.config.keyboard) {
      if (this.config.pageSteps === undefined) {
        this.config.pageSteps = 10;
      }
      for (const handle of this.handles) {
        handle.setAttribute('tabindex', 0);
        const onKeydown = this.config.onKeydown || this.defaultKeyHandler;
        this.ngZone.runOutsideAngular(() => {
          this.cleanups.push(this.renderer.listen(handle, 'keydown', onKeydown), this.renderer.listen(handle, 'click', () => {
            handle.focus();
          }));
        });
      }
    }
    this.slider.on('set', (values, handle, unencoded) => {
      this.eventHandler(this.set, values, handle, unencoded);
    });
    this.slider.on('update', (values, handle, unencoded) => {
      if (this.update.observers.length > 0) {
        this.ngZone.run(() => {
          this.update.emit(this.toValues(values));
        });
      }
    });
    this.slider.on('change', (values, handle, unencoded) => {
      if (this.change.observers.length > 0) {
        this.ngZone.run(() => {
          this.change.emit(this.toValues(values));
        });
      }
    });
    this.slider.on('slide', (values, handle, unencoded) => {
      this.eventHandler(this.slide, values, handle, unencoded);
    });
    this.slider.on('start', (values, handle, unencoded) => {
      if (this.start.observers.length > 0) {
        this.ngZone.run(() => {
          this.start.emit(this.toValues(values));
        });
      }
    });
    this.slider.on('end', (values, handle, unencoded) => {
      if (this.end.observers.length > 0) {
        this.ngZone.run(() => {
          this.end.emit(this.toValues(values));
        });
      }
    });
  }
}
NouisliderComponent.ɵfac = function NouisliderComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NouisliderComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
NouisliderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NouisliderComponent,
  selectors: [["nouislider"]],
  hostVars: 2,
  hostBindings: function NouisliderComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("ng2-nouislider", true);
    }
  },
  inputs: {
    disabled: "disabled",
    behaviour: "behaviour",
    connect: "connect",
    limit: "limit",
    min: "min",
    max: "max",
    snap: "snap",
    animate: "animate",
    range: "range",
    step: "step",
    format: "format",
    pageSteps: "pageSteps",
    config: "config",
    keyboard: "keyboard",
    onKeydown: "onKeydown",
    tooltips: "tooltips"
  },
  outputs: {
    change: "change",
    update: "update",
    slide: "slide",
    set: "set",
    start: "start",
    end: "end"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NouisliderComponent),
    multi: true
  }]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
  decls: 1,
  vars: 1,
  template: function NouisliderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div");
    }
    if (rf & 2) {
      i0.ɵɵattribute("disabled", ctx.disabled ? true : undefined);
    }
  },
  styles: ["[_nghost-%COMP%]{display:block;margin-top:1rem;margin-bottom:1rem}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NouisliderComponent, [{
    type: Component,
    args: [{
      selector: 'nouislider',
      host: {
        '[class.ng2-nouislider]': 'true'
      },
      template: '<div [attr.disabled]="disabled ? true : undefined"></div>',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NouisliderComponent),
        multi: true
      }],
      standalone: true,
      styles: [":host{display:block;margin-top:1rem;margin-bottom:1rem}\n"]
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    disabled: [{
      type: Input
    }],
    behaviour: [{
      type: Input
    }],
    connect: [{
      type: Input
    }],
    limit: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    snap: [{
      type: Input
    }],
    animate: [{
      type: Input
    }],
    range: [{
      type: Input
    }],
    step: [{
      type: Input
    }],
    format: [{
      type: Input
    }],
    pageSteps: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    keyboard: [{
      type: Input
    }],
    onKeydown: [{
      type: Input
    }],
    tooltips: [{
      type: Input
    }],
    change: [{
      type: Output
    }],
    update: [{
      type: Output
    }],
    slide: [{
      type: Output
    }],
    set: [{
      type: Output
    }],
    start: [{
      type: Output
    }],
    end: [{
      type: Output
    }]
  });
})();
class NouisliderModule {}
NouisliderModule.ɵfac = function NouisliderModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NouisliderModule)();
};
NouisliderModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NouisliderModule
});
NouisliderModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [NouisliderComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NouisliderModule, [{
    type: NgModule,
    args: [{
      exports: [NouisliderComponent],
      imports: [NouisliderComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng2-nouislider
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DefaultFormatter, NouisliderComponent, NouisliderModule };
